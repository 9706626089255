@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans'),
    url('./assets/fonts/NotoSansBengali-VariableFont_wdth,wght.ttf') format('truetype');
}

.ql-picker.ql-font {
  .ql-picker-item {
    font-size: 0;
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font{
  .ql-active {
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font .ql-picker-label[data-value="SansSerif"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="SansSerif"]::before
{
    font-family: "sans-serif", cursive;;
    content: "sans-serif" !important;
}

.ql-font-SansSerif {
  font-family: "sans-serif";
}

.ql-picker.ql-font .ql-picker-label[data-value="NotoSans"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="NotoSans"]::before
{
    font-family: "NotoSans", cursive;;
    content: "NotoSans" !important;
}

.ql-font-NotoSans {
  font-family: "NotoSans";
}

/* @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap'); */

body {
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: NotoSans, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
